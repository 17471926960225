import React, { useEffect, useState } from 'react';

import Breadcrumbs from '@/components/common/Breadcrumbs';
import ProductsList from '@/components/section/Product/List';
import { useProductImagesQuery } from '@/query/product_images';
import { useProductsQuery } from '@/query/products';
import { normalizeString } from '@/utils/string';
import { getLocalOrRemoteImages } from '@/utils/traversing';
import { getUrlQueryParams } from '@/utils/url';
import { ROUTE_NAMES } from '@/utils/url';

import * as style from './search.module.scss';

const SearchPage = ({ location }) => {
  const [searchResults, setSearchResults] = useState([]);
  const { q } = getUrlQueryParams(location);
  const breadcrumbs = [{ url: `/${ROUTE_NAMES.SEARCH}?q=${q}`, name: 'Resultados de búsqueda' }];
  const productsQuery = useProductsQuery();
  const productImagesQuery = useProductImagesQuery();
  const productImages = getLocalOrRemoteImages(productImagesQuery);

  useEffect(() => {
    const allProducts = productsQuery.allGoogleSpreadsheetProducts.edges.map((e) => e.node);
    const searchTerms = normalizeString(q)
      .split(' ')
      .filter((e) => e !== '');
    const results = allProducts.filter((product) => {
      const title = normalizeString(product.title);
      const shortDescription = normalizeString(product.shortDescription);
      const comparer = `${title} ${shortDescription}`;
      return searchTerms.every((term) => comparer.includes(term));
    });
    setSearchResults(results);
  }, [location.search]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <section className={style.main}>
      <Breadcrumbs breadcrumbs={breadcrumbs} />
      <h1>Resultados para "{q}"</h1>
      <ProductsList
        location={location}
        className={style.productsList}
        products={searchResults}
        images={productImages}
      />
    </section>
  );
};

export default SearchPage;
